import React from 'react'
import { graphql } from 'gatsby'
import CatHelmet from '../components/category/cathelmet'

import Layout from '../components/layout'
import Content from '../components/content'
import Top from '../components/menu/top'

import CatPostLink from '../components/category/catpost-link'
import CatMenu from '../components/category/cat-menu'
import CatCount from '../components/category/cat-count'

const catname = 'Labo'
const catclass = 'labo'

const LaboPage = ({
  data: {
    allMarkdownRemark: { edges, totalCount },
  },
}) => {
  const LaboPosts = edges.map(edge => (
    <CatPostLink key={edge.node.id} post={edge.node} />
  ))

  return (
    <Layout place="category">
      <CatHelmet catname={catname} />
      <Top />
      <Content color={catclass}>
        <CatCount catclass={catclass} catname={catname} count={totalCount} />

        <CatMenu catclass={catclass} catname={catname}>
          {LaboPosts}
        </CatMenu>
      </Content>
    </Layout>
  )
}

export default LaboPage

export const pageQuery = graphql`
  query LaboProj {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { category: { in: "labo" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "YYYY")
            path
            title
            category
            hierarchy
            idu
            featured {
              childImageSharp {
                fluid(
                  maxHeight: 350
                  maxWidth: 450
                  cropFocus: ENTROPY
                  quality: 65
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`
